import React, { useEffect, useState } from "react";
import { Button, Flex, Text } from "@chakra-ui/react";
import Header from "components/header";
import PersonaGeneral from '../../assets/img/PersonaGeneral.webp';
import PersonaDaily from '../../assets/img/PersonaDaily.webp';
import useStyles from './style';
import Footer from "components/footerUser";

const PersonaService = props => {
  const styles = useStyles();
  const [activePage, setActivePage] = useState(0);

  useEffect(() => {
    if (props?.location?.hash?.split('#')[1]) {
      setActivePage(props?.location?.hash?.split('#')[1])
    }
  }, [])

  return (
    <Flex direction="column" gap={5}>
      <Header active="home" />
      <Flex justifyContent="center" mt={16} width="100%">
        <Flex maxWidth={1080} width="100%" direction="column">
          <Flex gap={5}>
            <Flex direction="column" flex={1} maxWidth={{ sm: "100%", lg: "33%" }}>
              <img src={PersonaGeneral} alt="profile" className={styles.img} />
              <Flex width={{ sm: '100%', md: '100%' }} backgroundColor={activePage == 0 ? "#B02418" : "#016A70"} cursor="pointer" borderTopRadius={10} mb={2} p={2} h={70} justifyContent="center" alignItems="center" onClick={() => setActivePage(0)}>
                <Text as="h1" fontSize={{ sm: 'sm', md: 'md' }} color="#fff" fontWeight="bold" textAlign="center">GENERAL PERSONA</Text>
              </Flex>
            </Flex>
            <Flex direction="column" flex={1} maxWidth={{ sm: "100%", lg: "33%" }}>
              <img src={PersonaDaily} alt="profile" className={styles.img} />
              <Flex width={{ sm: '100%', md: '100%' }} backgroundColor={activePage == 1 ? "#B02418" : "#016A70"} cursor="pointer" borderTopRadius={10} mb={2} p={2} h={70} justifyContent="center" alignItems="center" onClick={() => setActivePage(1)}>
                <Text as="h1" fontSize={{ sm: 'sm', md: 'md' }} color="#fff" fontWeight="bold" textAlign="center">DAILY PERSONA</Text>
              </Flex>
            </Flex>
          </Flex>
          <Flex direction="column" padding={5} borderWidth={1} borderColor="#B02418" borderRadius={20}>
            {activePage == 0 ? (
              <Flex direction="column" gap={3}>
                <Text fontSize={{ sm: 'sm', md: 'md' }}>Layanan General Persona mencakup berbagai aspek yang memberikan gambaran menyeluruh tentang seseorang. Analisis kepribadian (Personality) dan sifat tersembunyi (Hidden Nature) membantu mengidentifikasi karakter positif dan negatif (Positive and Negative Character), serta sisi terang dan gelap dari individu tersebut (The Bright and The Dark). Selain itu, kekuatan dan kelemahan (Strength and Weakness) juga dievaluasi untuk memberikan pemahaman yang lebih dalam tentang potensi dan area perbaikan.</Text>
                <Text fontSize={{ sm: 'sm', md: 'md' }}>Lebih lanjut, General Persona juga mencakup gaya kerja (Work Style) dan gaya kepemimpinan (Leadership Style), serta analisis dalam konteks bisnis dan kekayaan (Business and Wealth). Kehidupan sosial (Social Life), kehidupan intim (Intimate Life), serta kehidupan cinta dan seks (Love and Sex Life) juga dianalisis untuk memberikan wawasan menyeluruh. Kesehatan (Health Life) tidak luput dari perhatian, dengan analisis tahunan (Annual Analytic) dan interpretasi tahunan (Annual Interpretation) yang membantu memetakan kondisi tahunan (Annual Condition) individu tersebut. Semua aspek ini bersama-sama memberikan pandangan komprehensif tentang kehidupan seseorang dan membantu mereka membuat keputusan yang lebih baik di berbagai bidang.</Text>
              </Flex>
            ) : activePage == 1 ? (
              <Flex direction="column" gap={3}>
                <Text fontSize={{ sm: 'sm', md: 'md' }}>Layanan Daily Vibe Persona memberikan analisis harian yang mendetail untuk membantu individu memahami dan memanfaatkan situasi sehari-hari. Melalui analisis harian (Daily Analytic), pengguna dapat memperoleh gambaran tentang situasi hari ini (Situation of the Day), yang mencakup aspek-aspek penting dalam kehidupan sehari-hari. Dengan informasi ini, individu dapat merencanakan aktivitas mereka dengan lebih efektif dan mengantisipasi tantangan yang mungkin muncul.</Text>
                <Text fontSize={{ sm: 'sm', md: 'md' }}>Selain itu, Daily Vibe Persona juga mencakup wawasan tentang cinta dan hubungan (Love and Relationship), kesehatan karakter (Character Health), serta rekomendasi untuk perjalanan dan rekreasi (Travel and Recreation). Analisis ini dilengkapi dengan evaluasi bisnis dan kekayaan (Business and Wealth), memberikan panduan yang berharga untuk pengambilan keputusan di bidang keuangan dan karir. Dengan layanan ini, pengguna dapat menjalani hari mereka dengan lebih terarah, produktif, dan harmonis, memaksimalkan potensi mereka di setiap aspek kehidupan.</Text>
              </Flex>
            ) : null}
            <Flex justify="end" align="end">
              <Button
                // width="100%"
                bgColor="#B02418"
                color='white'
                fontSize={{ sm: 'xs', md: 'sm' }}
                fontWeight='500'
                borderRadius='5px'
                onClick={() => window.location.assign('/pricing#persona')}
                px='15px'
                py='5px'>
                Lihat pricing
              </Button>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
      <Footer />
    </Flex>
  )
}

export default PersonaService;
