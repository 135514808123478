import {
  Avatar,
  Box,
  Button,
  Flex,
  Input,
  Progress,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
} from "@chakra-ui/react";
import React, { useMemo } from "react";
import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";

function TableFriends(props) {
  const { columnsData, tableData, title, pagination, nextPage, prevPage, firstPage, lastPage, goToPage, limitPage, type, onClickAction } = props;

  const columns = useMemo(() => columnsData, [columnsData]);
  const data = useMemo(() => tableData, [tableData]);

  const tableInstance = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const { getTableProps, getTableBodyProps, headerGroups, page, prepareRow } =
    tableInstance;

  const textColor = useColorModeValue("navy.700", "white");
  const textColorSecondary = useColorModeValue("secondaryGray.600", "white");
  return (
    <>
      <Flex
        direction='column'
        w={{md: '100%', lg: '70%'}}
        overflowX={{ sm: "scroll", lg: "hidden" }}>
        <Box sx={{ position: '-webkit-sticky', /* Safari */ position: 'sticky', top: '0', }}>
          <Text fontWeight="bold" fontSize={{sm: 'md', md: 'lg', lg: 'xl'}} color="green">{title}</Text>
        </Box>
        <Table {...getTableProps()} variant='simple' color='gray.500'>
          <Thead>
            {headerGroups.map((headerGroup, index) => (
              <Tr {...headerGroup.getHeaderGroupProps()} key={index}>
                {headerGroup.headers.map((column, index) => (
                  <Th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    pe='10px'
                    key={index}
                    borderColor='transparent'>
                    <Flex
                      fontSize={{ sm: "10px", lg: "12px" }}
                      color='gray.400'>
                        <Text fontWeight="bold" color="#000" fontSize={{ sm: "14px", lg: "18px" }}>{column.render("Header")}</Text>
                    </Flex>
                  </Th>
                ))}
              </Tr>
            ))}
          </Thead>

          <Tbody {...getTableBodyProps()}>
            {page.map((row, index) => {
              prepareRow(row);
              return (
                <Tr {...row.getRowProps()} key={index}>
                  {row.cells.map((cell, index) => {
                    let data = "";
                    if (cell.column.Header === "Action" && title === "Friends Request") {
                      data = (
                        <Flex flexDirection="row" gap={2}>
                          <Button
                            bgColor="#1877f2"
                            onClick={() => onClickAction({id: cell.value, value: 1})}
                            color='white'
                            fontSize={{sm: 'xs', md: 'sm'}}
                            fontWeight='bold'
                            borderRadius='5px'
                            px='10px'
                            py='5px'>
                            Accept
                          </Button>        
                          <Button
                            bgColor="red"
                            onClick={() => onClickAction({id: cell.value, value: 2})}
                            color='white'
                            fontSize={{sm: 'xs', md: 'sm'}}
                            fontWeight='bold'
                            borderRadius='5px'
                            px='10px'
                            py='5px'>
                            Decline
                          </Button>        
                        </Flex>
                      )
                    } else if (cell.column.Header === "Action") {
                      data = (
                        <Button
                          bgColor="#1877f2"
                          onClick={() => window.location.assign(`/dashboard/friend-list/${type === "friend" ? "friend" : "colleague"}/${cell.value}`)}
                          color='white'
                          fontSize={{sm: 'xs', md: 'sm'}}
                          fontWeight='bold'
                          borderRadius='5px'
                          px='30px'
                          py='5px'>
                          View
                        </Button>        
                      )
                    } else {
                      data = (
                        <Text
                          color={"black"}
                          fontSize={{sm: 'sm', md: 'md'}}
                          fontWeight='500'>
                          {cell.value ?? '-'}
                        </Text>
                      );
                    }
                    
                    return (
                      <Td
                        {...cell.getCellProps()}
                        key={index}
                        fontSize={{ sm: "14px" }}
                        minW={{ sm: "150px", md: "200px", lg: "auto" }}
                        borderColor='transparent'>
                        {data}
                      </Td>
                    );
                  })}
                </Tr>
              );
            })}
          </Tbody>
        </Table>
        <Flex direction="row" gap={2} alignItems="center" justifyContent="center" w={{sm: "max-content", lg: '100%'}}>
          <Button
            borderWidth={1}
            // borderColor="#1877f2"
            bg={"white"}
            color='black'
            fontSize={{sm: 'xs', md: 'sm'}}
            fontWeight='bold'
            borderRadius='5px'
            px='10px'
            py='5px'
            onClick={() => firstPage(type)}
            disabled={pagination.page === 1}
          >
            {'<<'}
          </Button>
          <Button
            borderWidth={1}
            // borderColor="#1877f2"
            bg={"white"}
            color='black'
            fontSize={{sm: 'xs', md: 'sm'}}
            fontWeight='bold'
            borderRadius='5px'
            px='10px'
            py='5px'
            onClick={() => prevPage(type)}
            disabled={pagination.page === 1}
          >
            {'<'}
          </Button>
          <Flex borderWidth={1} bg={"white"} borderRadius='5px' px='10px' py='5px' gap={2}>
            <Text color="#000" fontSize="md">Page </Text>
            <Input
                variant='search'
                fontSize={{sm: 'xs', md: 'sm'}}
                bg={"white"}
                color={"black"}
                w={10}
                fontWeight='500'
                borderRadius={5}
                textAlign="center"
                placeholder={pagination.page}
                value={pagination.page}
                onChange={e => {
                  const page = e.target.value
                  goToPage(type, page)
                }}
              />
            <Text color="#000" fontSize="md"> / {pagination.max}</Text>
          </Flex>
          <Button
            borderWidth={1}
            // borderColor="#1877f2"
            bg={"white"}
            color='black'
            fontSize={{sm: 'xs', md: 'sm'}}
            fontWeight='bold'
            borderRadius='5px'
            px='10px'
            py='5px'
            onClick={() => nextPage(type)}
            disabled={pagination.page === pagination.max}
          >
            {'>'}
          </Button>
          <Button
            borderWidth={1}
            // borderColor="#1877f2"
            bg={"white"}
            color='black'
            fontSize={{sm: 'xs', md: 'sm'}}
            fontWeight='bold'
            borderRadius='5px'
            px='10px'
            py='5px'
            onClick={() => lastPage(type)}
            disabled={pagination.page === pagination.max}
          >
            {'>>'}
          </Button>
          <select
          style={{padding: '5px 10px'}}
            value={pagination.limit}
            onChange={e => {
              limitPage(type, Number(e.target.value))
            }}
          >
            {[1, 5, 10, 20, 50].map(pageSize => (
              <option key={pageSize} value={pageSize}>
                Show {pageSize}
              </option>
            ))}
          </select>
          <Text fontSize={{sm: 'xs', md: 'sm'}}>{pagination.limit > pagination.total ? pagination.total : pagination.limit} of {pagination.total}</Text>
        </Flex>
      </Flex>
    </>
  );
}

export default TableFriends;


{/* <div className="flex items-center gap-2">
        <button
          className="border rounded p-1"
          onClick={() => table.firstPage()}
          disabled={!table.getCanPreviousPage()}
        >
          {'<<'}
        </button>
        <button
          className="border rounded p-1"
          onClick={() => table.previousPage()}
          disabled={!table.getCanPreviousPage()}
        >
          {'<'}
        </button>
        <button
          className="border rounded p-1"
          onClick={() => table.nextPage()}
          disabled={!table.getCanNextPage()}
        >
          {'>'}
        </button>
        <button
          className="border rounded p-1"
          onClick={() => table.lastPage()}
          disabled={!table.getCanNextPage()}
        >
          {'>>'}
        </button>
        <span className="flex items-center gap-1">
          <div>Page</div>
          <strong>
            {table.getState().pagination.pageIndex + 1} of{' '}
            {table.getPageCount().toLocaleString()}
          </strong>
        </span>
        <span className="flex items-center gap-1">
          | Go to page:
          <input
            type="number"
            defaultValue={table.getState().pagination.pageIndex + 1}
            onChange={e => {
              const page = e.target.value ? Number(e.target.value) - 1 : 0
              table.setPageIndex(page)
            }}
            className="border p-1 rounded w-16"
          />
        </span>
        <select
          value={table.getState().pagination.pageSize}
          onChange={e => {
            table.setPageSize(Number(e.target.value))
          }}
        >
          {[10, 20, 30, 40, 50].map(pageSize => (
            <option key={pageSize} value={pageSize}>
              Show {pageSize}
            </option>
          ))}
        </select>
      </div> */}