import { makeStyles } from "@mui/styles";

export default makeStyles({
  gridPrice: {
    '&:hover': {
      transition: 'transform 0.2s',
      transformOrigin: '50% 100%',
      transform: 'scale(1.01)',
    },
  },
  pricingCard: {
    marginBottom: 20,
    boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.2)",
    textAlign: "center",
    minWidth: 1000/4
  },
  pricingCardTitle: {
    fontSize: 18,
    backgroundColor: "#1A7431",
    color: "#fff",
    padding: 20,
    fontWeight: "bold!important"
  },
  pricingCardPrice: {
    fontSize: '16px!important',
    padding: "20px 0 0 20px",
    fontWeight: "bold!important"
  },
  pricingCardPeriod: {
      padding: "20px 20px 0 0"
  },
  pricingCardDesc: {
      color: "#3F562A",
      fontWeight: "bold!important",
      padding: 10
  },
  pricingCardFeatures: {
    fontSize: 14,
  },
  cardDesc: {
    minHeight: "55vh",
    textAlign: 'start'
  },
  containerPricing: {
    flexDirection: "column!important",
    '& del': {
      color: 'red',
      // textDecoration: 'none',
      position: 'relative',
      '&:before': {
        content: " ",
        display: 'block',
        width: '100%',
        borderTop: '2px solid rgba(red, 0.8)',
        height: 12,
        position: 'absolute',
        bottom: 0,
        left: 0,
        transform: 'rotate(-7deg)',
      }
    },
    '& ins': {
      color: 'green',
      fontSize: '18px!important',
      textDecoration: 'none',
      display: 'flex',
      justifyContent: 'center',
      padding: '0',
    }
  },
  titleService: {
    fontSize: '18px!important',
    fontWeight: 'bold!important',
    padding: '0 20px'
  },
  btnBuy: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '5px 40px',
    fontSize: 11,
    fontWeight: 'bold',
    gap: 10,
    backgroundColor: '#fff',
    borderWidth: 1,
    borderColor: '#E24E06',
    color: '#E24E06',
    borderRadius: 8,
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#E24E06',
      borderWidth: 1,
      borderColor: '#E24E06',
      color: '#fff',  
    }
  },
  btnPurchased: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '5px 40px',
    fontSize: 11,
    fontWeight: 'bold',
    gap: 10,
    backgroundColor: '#fff',
    // borderWidth: 1,
    // borderColor: '#E24E06',
    color: '#E24E06',
    borderRadius: 8,
    // '&:hover': {
    //   backgroundColor: '#E24E06',
    //   borderWidth: 1,
    //   borderColor: '#E24E06',
    //   color: '#fff',  
    // }
  },

});
  