import React, { useEffect, useState } from "react";

// Chakra imports
import {
  Box,
  Button,
  Flex,
  Grid,
  Link,
  Text,
  useColorModeValue,
  SimpleGrid,
  Input,
} from "@chakra-ui/react";
import axios from "axios";
import qs from 'qs';

// Custom components
import Banner from "views/admin/profile/components/Banner";

// Assets
import tableDataTopCreators from "views/admin/marketplace/variables/tableDataTopCreators.json";
import { tableColumnsTopCreators } from "views/admin/marketplace/variables/tableColumnsTopCreators";
import TableFriends from "./components/TableFriends";
import { columnsDataFriendList, columnsDataFriends, columnsDataFriendsRequest } from "./variables/columnsData";
import { Divider } from "@mui/material";

export default function FriendList() {
  const [user, setUser] = useState();
  const [friends, setFriends] = useState([]);
  const [colleague, setColleague] = useState([]);
  const [friendRequest, setFriendRequest] = useState([]);
  const [activeMenu, setActiveMenu] = useState(0);
  // const navigate = useNavigate()
  // const styles = useStyles();

  const [paginationFriend, setPaginationFriend] = useState({ limit: 5, page: 1, max: 1, total: 1 })
  const [paginationFriendRequest, setPaginationFriendRequest] = useState({ limit: 5, page: 1, max: 1, total: 1 })
  const [paginationColleague, setPaginationColleague] = useState({ limit: 5, page: 1, max: 1, total: 1 })

  useEffect(() => {
    let localUser = localStorage.getItem('user');
    if (localUser) {
      setUser(JSON.parse(localUser))
    } else {
      // navigate('/')
    }
  }, []);

  useEffect(() => {
    const token = localStorage.getItem('token');
    axios.get(`https://api.personatalenta.id/user/friendship/?limit=${paginationFriend.limit}&page=${paginationFriend.page}`, { headers: { Authorization: `Bearer ${token ? token : ''}`, "Content-Type": "x-www-from-urlencode" } })
      .then(res => {
        setFriends(res.data?.friendship)
        setPaginationFriend({ ...paginationFriend, max: res.data.totalPages, total: res.data.totalCount })
      }).catch(err => {
        if (err.response?.data?.message === "Unauthorized" || err.response?.data === "Unauthorized") {
          // navigate('/');
        }
      })
  }, [paginationFriend.page, paginationFriend.limit]);

  useEffect(() => {
    const token = localStorage.getItem('token');
    axios.get(`https://api.personatalenta.id/user/friendship/request?limit=${paginationFriendRequest.limit}&page=${paginationFriendRequest.page}`, { headers: { Authorization: `Bearer ${token ? token : ''}`, "Content-Type": "x-www-from-urlencode" } })
      .then(res => {
        setFriendRequest(res.data?.friendship)
        setPaginationFriendRequest({ ...paginationFriendRequest, max: res.data.totalPages, total: res.data.totalCount })
      }).catch(err => {
        if (err.response?.data?.message === "Unauthorized" || err.response?.data === "Unauthorized") {
          // navigate('/');
        }
      })
  }, [paginationFriendRequest.page, paginationFriendRequest.limit]);

  useEffect(() => {
    const token = localStorage.getItem('token');
    axios.get(`https://api.personatalenta.id/user/colleague?limit=${paginationColleague.limit}&page=${paginationColleague.page}`, { headers: { Authorization: `Bearer ${token ? token : ''}`, "Content-Type": "x-www-from-urlencode" } })
      .then(res => {
        setColleague(res?.data?.colleagues)
        setPaginationColleague({ ...paginationColleague, max: res.data.totalPages, total: res.data.totalCount })
      }).catch(err => {
        if (err.response?.data?.message === "Unauthorized" || err?.response?.data === "Unauthorized") {
          // navigate('/');
        }
      })
  }, [paginationColleague.page, paginationColleague.limit])


  useEffect(() => {
    let localUser = localStorage.getItem('user');
    if (localUser) {
      setUser(JSON.parse(localUser))
      // console.log(user)
    } else {
      window.location.assign('/home')
    }
  }, []);

  useEffect(() => {
    window.onscroll = function () { myFunction() };

    var navbar = document.getElementById("sticky");
    // var type = document.getElementById("type");
    var sticky = navbar.offsetTop - 50;

    function myFunction() {
      if (window.pageYOffset >= sticky) {
        navbar.classList.add("sticky")
        // type.classList.add("sticky")
      } else {
        navbar.classList.remove("sticky");
        // type.classList.remove("sticky");
      }
    }
  }, [window])

  const nextPage = (type) => {
    if (type === "friend") {
      if (paginationFriend.page < paginationFriend.max) {
        setPaginationFriend({ ...paginationFriend, page: paginationFriend.page + 1 })
      }
    } else if (type === "request") {
      if (paginationFriendRequest.page < paginationFriendRequest.max) {
        setPaginationFriendRequest({ ...paginationFriendRequest, page: paginationFriendRequest.page + 1 })
      }
    } else if (type === "colleague") {
      if (paginationColleague.page < paginationColleague.max) {
        setPaginationColleague({ ...paginationColleague, page: paginationColleague.page + 1 })
      }
    }
  };

  const prevPage = (type) => {
    if (type === "friend") {
      if (paginationFriend.page > 1) {
        setPaginationFriend({ ...paginationFriend, page: paginationFriend.page - 1 })
      }
    } else if (type === "request") {
      if (paginationFriendRequest.page > 1) {
        setPaginationFriendRequest({ ...paginationFriendRequest, page: paginationFriendRequest.page - 1 })
      }
    } else if (type === "colleague") {
      if (paginationColleague.page > 1) {
        setPaginationColleague({ ...paginationColleague, page: paginationColleague.page - 1 })
      }
    }
  };

  const firstPage = (type) => {
    if (type === "friend") {
      setPaginationFriend({ ...paginationFriend, page: 1 })
    } else if (type === "request") {
      setPaginationFriendRequest({ ...paginationFriendRequest, page: 1 })
    } else if (type === "colleague") {
      setPaginationColleague({ ...paginationColleague, page: 1 })
    }
  };

  const lastPage = (type) => {
    if (type === "friend") {
      setPaginationFriend({ ...paginationFriend, page: paginationFriend.max })
    } else if (type === "request") {
      setPaginationFriendRequest({ ...paginationFriendRequest, page: paginationFriendRequest.max })
    } else if (type === "colleague") {
      setPaginationColleague({ ...paginationColleague, page: paginationColleague.max })
    }
  };

  const goToPage = (type, page) => {
    if (type === "friend") {
      setPaginationFriend({ ...paginationFriend, page: page })
    } else if (type === "request") {
      setPaginationFriendRequest({ ...paginationFriendRequest, page: page })
    } else if (type === "colleague") {
      setPaginationColleague({ ...paginationColleague, page: page })
    }
  };

  const limitPage = (type, limit) => {
    if (type === "friend") {
      setPaginationFriend({ ...paginationFriend, limit: limit })
    } else if (type === "request") {
      setPaginationFriendRequest({ ...paginationFriendRequest, limit: limit })
    } else if (type === "colleague") {
      setPaginationColleague({ ...paginationColleague, limit: limit })
    }
  };

  const onClickAction = props => {
    const token = localStorage.getItem('token');
    axios.post(`https://api.personatalenta.id/user/friendship/respond-request/${props.id}`, qs.stringify({ status: props.value }), { headers: { "Content-Type": 'application/x-www-form-urlencoded', Authorization: `Bearer ${token}` } })
      .then(() => {
        window.location.reload()
      }).catch(err => {
        console.log('err: ', err)
        // setError(err.response.data.message || err.response.data)
      })
  }

  const onChangeMenu = menu => {
    if (menu === 0) {
      setActiveMenu(0);
    } else if (menu === 1) {
      setActiveMenu(1);
    } else if (menu === 2) {
      setActiveMenu(2);
    }
  }


  // Chakra Color Mode
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const textColorBrand = useColorModeValue("brand.500", "white");
  return (
    <Box pt={{ sm: 10, base: "130px", md: "80px", xl: "80px" }} mt={{ sm: 15, md: 5 }}>
      <Grid
        gap={{ base: "20px", xl: "20px" }}>
        <Flex gap={{ sm: 1, md: 30 }} flex={1} id="sticky">
          <Flex gap={5} width={{ sm: '100%', md: "20%" }} direction="column">
            <Flex cursor="pointer" onClick={() => onChangeMenu(0)} borderColor="#059212" borderWidth={1} borderTopRadius={8} padding="5px 10px" backgroundColor={activeMenu === 0 ? '#059212' : '#fff'}>
              <Text fontSize={{ sm: 'xs', md: 'sm' }} color={activeMenu === 0 ? '#FFF' : '#000'} fontWeight={activeMenu === 0 ? 'bold' : 'normal'}>Friends Colleague</Text>
            </Flex>
            <Button
              borderWidth={1}
              bgColor="#1877f2"
              borderColor="#1877f2"
              color='#fff'
              fontSize='sm'
              fontWeight='bold'
              borderRadius='5px'
              px='10px'
              py='5px'
              onClick={() => window.location.assign('/dashboard/friend-list/add-colleague')}
            >
              Add Colleague
            </Button>
          </Flex>
          <Flex gap={5} width={{ sm: '100%', md: "20%" }} direction="column">
            <Flex cursor="pointer" onClick={() => onChangeMenu(1)} borderColor="#059212" borderWidth={1} borderTopRadius={8} padding="5px 10px" backgroundColor={activeMenu === 1 ? '#059212' : '#fff'}>
              <Text fontSize={{ sm: 'xs', md: 'sm' }} color={activeMenu === 1 ? '#FFF' : '#000'} fontWeight={activeMenu === 1 ? 'bold' : 'normal'}>Friends From Apps</Text>
            </Flex>
            <Button
              borderWidth={1}
              borderColor="#1877f2"
              bgColor="#1877f2"
              color='#fff'
              fontSize='sm'
              fontWeight='bold'
              borderRadius='5px'
              px='10px'
              py='5px'
              onClick={() => window.location.assign('/dashboard/friend-list/add-friend')}
            >
              Add Friend Apps
            </Button>
          </Flex>
        </Flex>
        <Divider orientation="horizontal" style={{ width: '75%' }} />
        {activeMenu === 0 ? (
          <Grid mb={20}>
            <TableFriends
              columnsData={columnsDataFriendList}
              tableData={colleague}
              // title="Friends Colleague"
              type="colleague"
              pagination={paginationColleague}
              nextPage={nextPage}
              prevPage={prevPage}
              firstPage={firstPage}
              lastPage={lastPage}
              limitPage={limitPage}
              goToPage={goToPage}
            />
          </Grid>
        ) : (
          <Grid mb={20}>
            <TableFriends
              columnsData={columnsDataFriends}
              tableData={friends}
              // title="Friends Apps"
              type="friend"
              pagination={paginationFriend}
              nextPage={nextPage}
              prevPage={prevPage}
              firstPage={firstPage}
              lastPage={lastPage}
              limitPage={limitPage}
              goToPage={goToPage}
            />
          </Grid>
        )}
      </Grid>
    </Box>
  );
}
