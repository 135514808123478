import {
  Flex,
  Box,
  Table,
  Checkbox,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  Button,
  Spinner,
} from "@chakra-ui/react";
import React, { useEffect, useMemo, useState } from "react";
import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";
import Divider from '@mui/material/Divider';
import DownloadIcon from '@mui/icons-material/Download';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
// Custom components
import Card from "components/card/Card";
import Menu from "components/menu/MainMenu";
import { CircularProgress } from "@mui/material";
export default function ServiceTable(props) {
  const { columnsData, tableData, onClickCategory, onClickBuy, onClickPrice, onClickDetail, onClickDownload, loading, friend } = props;
  const [activeMenu, setActiveMenu] = useState('profiling');
  const [serviceData, setServiceData] = useState([]);
  const columns = useMemo(() => columnsData, [columnsData]);
  const data = useMemo(() => tableData, [tableData]);

  useEffect(() => {
    if (data) {
      setServiceData(data.profiling)
    }
  }, [data])

  const onChangeMenu = menu => {
    if (menu === 'profiling') {
      setActiveMenu('profiling');
      setServiceData(data.profiling);
    } else if (menu === 'matching') {
      setActiveMenu('matching');
      setServiceData(data.matching);
    } else if (menu === 'persona') {
      setActiveMenu('persona');
      setServiceData(data.persona);
    }
  }

  const handleDownload = ({ category, name, index }) => {
    onClickDownload({ category, name, index })
  }


  // const tableInstance = useTable(
  //   {
  //     columns,
  //     data,
  //   },
  //   useGlobalFilter,
  //   useSortBy,
  //   usePagination
  // );

  // const {
  //   getTableProps,
  //   getTableBodyProps,
  //   headerGroups,
  //   page,
  //   prepareRow,
  //   initialState,
  // } = tableInstance;
  // initialState.pageSize = 11;

  const textColor = useColorModeValue("secondaryGray.900", "white");
  const borderColor = useColorModeValue("gray.200", "whiteAlpha.100");
  return (
    <Card
      direction='column'
      w={{ md: '100%', lg: '80%' }}
      px='0px'
      overflowX={{ sm: "scroll", lg: "hidden" }}>
      <Flex gap={{ sm: 1, md: 30 }} flex={1} mb={5}>
        <Flex width={{ sm: '100%', md: "20%" }} cursor="pointer" onClick={() => onChangeMenu('profiling')} borderColor="#059212" borderWidth={1} borderTopRadius={8} padding="5px 10px" backgroundColor={activeMenu === 'profiling' ? '#059212' : '#fff'}>
          <Text fontSize={{ sm: 'xs', md: 'sm' }} color={activeMenu === 'profiling' ? '#FFF' : '#000'} fontWeight={activeMenu === 'profiling' ? 'bold' : 'normal'}>Profiling Service</Text>
        </Flex>
        {!friend && (
          <Flex width={{ sm: '100%', md: "20%" }} cursor="pointer" onClick={() => onChangeMenu('matching')} borderColor="#059212" borderWidth={1} borderTopRadius={8} padding="5px 10px" backgroundColor={activeMenu === 'matching' ? '#059212' : '#fff'}>
            <Text fontSize={{ sm: 'xs', md: 'sm' }} color={activeMenu === 'matching' ? '#FFF' : '#000'} fontWeight={activeMenu === 'matching' ? 'bold' : 'normal'}>Matching Service</Text>
          </Flex>
        )}
        <Flex width={{ sm: '100%', md: "20%" }} cursor="pointer" onClick={() => onChangeMenu('persona')} borderColor="#059212" borderWidth={1} borderTopRadius={8} padding="5px 10px" backgroundColor={activeMenu === 'persona' ? '#059212' : '#fff'}>
          <Text fontSize={{ sm: 'xs', md: 'sm' }} color={activeMenu === 'persona' ? '#FFF' : '#000'} fontWeight={activeMenu === 'persona' ? 'bold' : 'normal'}>Persona Service</Text>
        </Flex>
      </Flex>
      <Flex width="100%" gap={{ sm: 1, md: 5 }}>
        <Flex flex={3} width="100%" borderTopRadius={8} padding="5px 10px" backgroundColor={'#CADFB8'}>
          <Text color={'#000'} fontSize={{ sm: 'xs', md: 'sm' }} fontWeight='bold'>Type {activeMenu}</Text>
        </Flex>
        <Flex flex={1} width="100%" borderTopRadius={8} padding="5px 10px" backgroundColor={'#CADFB8'}>
          <Text color={'#000'} fontSize={{ sm: 'xs', md: 'sm' }} fontWeight='bold'>Pricing</Text>
        </Flex>
        <Flex flex={1} width="100%" borderTopRadius={8} padding="5px 10px" backgroundColor={'#CADFB8'}>
          <Text color={'#000'} fontSize={{ sm: 'xs', md: 'sm' }} fontWeight='bold'>Action</Text>
        </Flex>
        <Flex flex={3} width="100%" borderTopRadius={8} padding="5px 10px" backgroundColor={'#CADFB8'}>
          <Text color={'#000'} fontSize={{ sm: 'xs', md: 'sm' }} fontWeight='bold'>Description</Text>
        </Flex>
      </Flex>
      <Flex width="100%" direction="column" gap={2} mt={2}>
        {serviceData.map((item, index) => {
          const isPurchase = serviceData.find(element => (element.name === item.name && element.isPurchase === true));
          return (
          <Flex direction="row" width="100%" gap={{ sm: 1, md: 5 }} key={index} alignItems="center">
            <Flex flex={3} width="100%" minWidth={{ sm: '120px', md: '120px' }}>
              <Text fontSize={{ sm: 'xs', md: 'sm' }} color={'#000'}>{index + 1}. {item.name} {item.duration && `${item.duration} ${item.duration_type}`}</Text>
            </Flex>
            <Flex flex={1} width="100%">
              {item.isPurchase ? (
                <Text flex={1} fontSize={{ sm: 'xs', md: 'sm' }} minWidth={{ sm: '65px', md: '100px' }}>Sudah beli</Text>
              ) : (
                <Text cursor="pointer" textDecoration="underline" flex={1} fontSize={{ sm: 'xs', md: 'sm' }} minWidth={{ sm: '65px', md: '100px' }} onClick={() => onClickPrice({ category: item.category, service: activeMenu })}>Rp {new Intl.NumberFormat('id-ID').format(item.price)}</Text>
              )}
            </Flex>
            <Flex flex={1} width="100%">
              {item.isPurchase || isPurchase ? (
                <Flex flexDirection="row" width="100%" gap={1}>
                  <Flex flex={1}>
                    {item.type === "profiling" ? (
                      <Button
                        width="100%"
                        bgColor="green"
                        color='white'
                        fontSize={{ sm: 'xs', md: 'sm' }}
                        fontWeight='500'
                        borderRadius='5px'
                        onClick={() => handleDownload({ category: item.category, service: activeMenu, name: item.features, index: index })}
                        px='5px'
                        py='5px'
                        title="Download PDF">
                        {loading[index] ? (
                          <CircularProgress
                            thickness={4}
                            size={15}
                            sx={{
                              color: (theme) =>
                                theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
                            }}
                          />
                        ) : (
                          <DownloadIcon fontSize="sm" />
                        )}
                      </Button>
                    ) : (
                      <Button
                        width="100%"
                        bgColor="green"
                        color='white'
                        fontSize={{ sm: 'xs', md: 'sm' }}
                        fontWeight='500'
                        borderRadius='5px'
                        onClick={() => onClickCategory({ category: item.category, service: item.type, name: item.features })}
                        px='5px'
                        py='10px'
                        title="Open Web View">
                        <OpenInNewIcon fontSize="sm" />
                      </Button>
                    )}
                  </Flex>
                </Flex>
              ) : (
                <Flex flex={1}>
                  <Button
                    width="100%"
                    bgColor="green"
                    color='white'
                    fontSize={{ sm: 'xs', md: 'sm' }}
                    fontWeight='500'
                    borderRadius='5px'
                    onClick={() => onClickBuy({ category: item.category, service: activeMenu, name: item.name })}
                    px='15px'
                    py='5px'>
                    Beli
                  </Button>
                </Flex>
              )}
            </Flex>
            <Flex flex={3} width="100%">
              <Text fontSize={{ sm: 'xs', md: 'sm' }} color={'#000'}>{item.desc}</Text>
            </Flex>
          </Flex>
        )})}
      </Flex>
    </Card>
  );
}
