import React, { useEffect, useState } from "react";
import axios from 'axios';
import { Card, Grid, Typography } from "@mui/material";
import useStyles from './style';
import { pricingMatchingStatic } from "constant/product";
import { Flex, Text } from "@chakra-ui/react";
// import {pricingMatchingStatic} from '../../constant/product'

const PricingMatching = props => {
  const classes = useStyles();
  const [pricingPlans, setPricingPlans] = useState([]);

  useEffect(() => {
    const token = localStorage.getItem('token');
    const tmpPricing = pricingMatchingStatic;
    axios.get(`https://api.personatalenta.id/product/list`, { headers: { Authorization: `Bearer ${token ? token : ''}`, "Content-Type": "x-www-from-urlencode", 'Access-Control-Allow-Origin': 'http://localhost:3000', 'Access-Control-Allow-Credentials': 'true', 'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept' } })
      .then(res => {
        const data = res.data.products.filter((product) => product.type === "matching")
        data.map((product) => {
          if (product.code === "matching_partnership_15") {
            tmpPricing[0].name = product.name
            tmpPricing[0].product[0].id = product.id
            tmpPricing[0].product[0].discountPrice = product.price
            tmpPricing[0].product[0].price = product.old_price
            tmpPricing[0].product[0].duration = product.duration
            tmpPricing[0].product[0].duration_type = product.duration_type
          } else if (product.code === "matching_partnership_25") {
            tmpPricing[0].name = product.name
            tmpPricing[0].product[1].id = product.id
            tmpPricing[0].product[1].discountPrice = product.price
            tmpPricing[0].product[1].price = product.old_price
            tmpPricing[0].product[1].duration = product.duration
            tmpPricing[0].product[1].duration_type = product.duration_type
          } else if (product.code === "matching_romance_15") {
            tmpPricing[1].name = product.name
            tmpPricing[1].product[0].id = product.id
            tmpPricing[1].product[0].discountPrice = product.price
            tmpPricing[1].product[0].price = product.old_price
            tmpPricing[1].product[0].duration = product.duration
            tmpPricing[1].product[0].duration_type = product.duration_type
          } else if (product.code === "matching_romance_25") {
            tmpPricing[1].name = product.name
            tmpPricing[1].product[1].id = product.id
            tmpPricing[1].product[1].discountPrice = product.price
            tmpPricing[1].product[1].price = product.old_price
            tmpPricing[1].product[1].duration = product.duration
            tmpPricing[1].product[1].duration_type = product.duration_type
          } else if (product.code === "matching_friendship_15") {
            tmpPricing[2].name = product.name
            tmpPricing[2].product[0].id = product.id
            tmpPricing[2].product[0].discountPrice = product.price
            tmpPricing[2].product[0].price = product.old_price
            tmpPricing[2].product[0].duration = product.duration
            tmpPricing[2].product[0].duration_type = product.duration_type
          } else if (product.code === "matching_friendship_25") {
            tmpPricing[2].name = product.name
            tmpPricing[2].product[1].id = product.id
            tmpPricing[2].product[1].discountPrice = product.price
            tmpPricing[2].product[1].price = product.old_price
            tmpPricing[2].product[1].duration = product.duration
            tmpPricing[2].product[1].duration_type = product.duration_type
          }
        })
        setPricingPlans(tmpPricing)
      }).catch(err => {
        if (err.response.data.message === "Unauthorized" || err.response.data === "Unauthorized") {
          // navigate('/');
        }
      })
  }, []);

  const handleBuy = plan => {
    if (props?.data.type === "colleague" || props?.data.type === "friend") {
      window.location.assign(`/dashboard/friend-list/${props.data.type}/${props.data.id}/checkout/${plan?.id}`)
    } else if (props?.user) {
      window.location.assign(`/dashboard/profile/${props?.user?.username}/checkout/${plan?.id}`)
    } else {
      window.location.assign('/login?service=matching')
    }
  }

  return (
    <Grid container spacing={3} width="100%" flexWrap="nowrap" overflow="auto" style={{ scrollbarWidth: 'none' }}>
      {pricingPlans.map((plan, index) => {
        const isPurchase = props.product?.find(element => (element.name === plan.name && element.isPurchase === true))
        return (
          <Grid item xs={12} sm={6} md={4} width={1080 / 4} key={index} className={classes.gridPrice}>
            <Card className={classes.pricingCard}>
              <Typography variant="h6" component="div" gutterBottom className={plan.name === "Partnership Matching" ? classes.pricingCardTitlePartnership : plan.name === "Romance Matching" ? classes.pricingCardTitleRomance : classes.pricingCardTitle}>
                {plan.name}
              </Typography>
              <Flex flexDirection="row" width="100%" flex={1} gap={6} justifyContent="center" pb={5}>
                {plan.product.map((item, index) => (
                  <Flex key={index} className={classes.containerPricing} gap={2}>
                    <Typography variant="h6" className={classes.pricingDuration}>
                      {item.duration} {item.duration_type}
                    </Typography>
                    <Typography variant="h6" component="del" className={classes.pricingCardPrice}>
                      Rp {new Intl.NumberFormat('id-ID').format(item.price)}
                    </Typography>
                    <Typography variant="h6" component="ins" className={classes.pricingCardPrice}>
                      Rp {new Intl.NumberFormat('id-ID').format(item.discountPrice)}
                    </Typography>
                    {isPurchase ? (
                      <div className={classes.btnPurchased}>
                        <span>Sudah Beli</span>
                      </div>
                    ) : (
                      <div className={classes.btnBuy} onClick={() => handleBuy(item)}>
                        <span>Beli</span>
                      </div>
                    )}
                  </Flex>
                ))}
              </Flex>
            </Card>
            <Typography variant="caption" component="div" className={classes.pricingCardDesc}>
              {plan.desc}
            </Typography>
            <Card className={classes.cardDesc}>
              <Typography variant="h6" component="div" className={classes.titleService}>
                Service {plan.name}:
              </Typography>
              <Typography variant="body1" component="div" gutterBottom className={classes.pricingCardFeatures}>
                <ul>
                  {plan.features.map((feature, index) => (
                    <li key={index}>{feature}</li>
                  ))}
                </ul>
              </Typography>
            </Card>
          </Grid>
        )
      })}
    </Grid>
  );
};

export default PricingMatching;
