import React, { useState } from "react";
import TextField from '@mui/material/TextField';
import OutlinedInput from '@mui/material/OutlinedInput';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import axios from 'axios';
import qs from 'qs';
// import { useNavigate } from 'react-router-dom';

// import Header from "../../components/Header";
import useStyles from './style';
import Header from "components/header";
import { Button, Flex, Text } from "@chakra-ui/react";
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Footer from "components/footerUser";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { InputAdornment } from "@mui/material";

// import Button from '../../components/Button';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

const Signup = () => {
  // const navigate = useNavigate();
  const [params, setParams] = useState({});
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState('');
  const [open, setOpen] = useState(false);
  const [active, setActive] = useState(0);
  const styles = useStyles();

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleRegister = () => {
    axios.post(`https://api.personatalenta.id/user/register`, qs.stringify({ ...params }), { headers: { 'content-type': 'application/x-www-form-urlencoded' } })
      .then((res) => {
        if (res.data.message === "Pendaftaran berhasil") {
          setOpen(true)
        }
      }).catch(err => {
        setActive(0);
        setError(err.response.data.message || err.response.data)
      })
  };

  const onChangeInput = e => {
    const tmpValue = {};
    if (e.target.id === 'username') {
      tmpValue.name = e.target.value
      tmpValue[e.target.id] = e.target.value;
      setParams({ ...params, ...tmpValue });
    } else if (e.target.id === 'password') {
      tmpValue.password1 = e.target.value;
      tmpValue.password2 = e.target.value;
      setParams({ ...params, ...tmpValue });
    } else {
      tmpValue[e.target.id] = e.target.value;
      setParams({ ...params, ...tmpValue });
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleNext = () => {
    if (active === 0) {
      if (!params.username) {
        setError('Username is required')
      } else if (!params.email) {
        setError('Email is required')
      } else if (!params.password1 || !params.password2) {
        setError('Password is Required')
      } else {
        setError('')
        setActive(active + 1)
      }
    } else if (active === 1) {
      if (!params.birthDate) {
        setError('Birth date is required');
      } else if (!params.birthTime) {
        setError('Birth time is required')
      } else if (!params.gender) {
        setError('Gender is required')
      } else {
        setError('')
        handleRegister();
      }
    }
  }

  return (
    <>
      <div className={styles.app}>
        <Header />
        <div className={styles.containerListItem}>
          {/* <p>Perluas wawasanmu untuk memahami diri sendiri, pasangan, sahabat bahkan klien bisnis ataupun mitra kerjamu dengan akses ke layanan-layanan yang kami sediakan. Temukan dunia baru, untuk memahami orang lain tanpa harus bertemu muka, Bergabung dengan komunitas kami!</p>
          <p><strong>Registrasi sekarang dan temukan teman-teman baru serta berbagai konten menarik yang pasti membuat harimu lebih berwarna:</strong></p> */}
          <div>
            <div className={styles.containerInput}>
              <div className="list">
                {error && (
                  <Flex bgColor="red" borderRadius={10} px={5} my={5}>
                    <Text color="white">{error}</Text>
                  </Flex>
                )}
                {/* <Flex flexDirection={{sm: 'column', md: "row"}} mt={2}>
                  <Flex flex={2}>
                    <p>Display name</p>
                  </Flex>
                  <Flex flex={0.5} display={{sm: 'none', md: 'flex'}}>
                    <p>:</p>
                  </Flex>
                  <Flex flex={12}>
                    <TextField
                      hiddenLabel
                      fullWidth
                      id="name"
                      defaultValue={params.name ?? ""}
                      variant="outlined"
                      size="small"
                      onChange={onChangeInput}
                      placeholder="minimal 6 huruf"
                    />
                  </Flex>
                </Flex> */}
                {active === 0 ? (
                  <>
                    <Flex flexDirection={{ sm: 'column', md: "row" }}>
                      <Flex flex={2}>
                        <Text fontSize={{ sm: 'sm', md: 'md' }}>Username</Text>
                      </Flex>
                      <Flex flex={0.5} display={{ sm: 'none', md: 'flex' }}>
                        <Text fontSize={{ sm: 'sm', md: 'md' }}>:</Text>
                      </Flex>
                      <Flex flex={10}>
                        <TextField
                          hiddenLabel
                          fullWidth
                          id="username"
                          defaultValue={params.username ?? ""}
                          variant="outlined"
                          size="small"
                          onChange={onChangeInput}
                          placeholder="minimal 6 huruf"
                        />
                      </Flex>
                    </Flex>
                    <Flex flexDirection={{ sm: 'column', md: "row" }}>
                      <Flex flex={2}>
                        <Text fontSize={{ sm: 'sm', md: 'md' }}>Email Address</Text>
                      </Flex>
                      <Flex flex={0.5} display={{ sm: 'none', md: 'flex' }}>
                        <Text fontSize={{ sm: 'sm', md: 'md' }}>:</Text>
                      </Flex>
                      <Flex flex={10}>
                        <TextField
                          hiddenLabel
                          fullWidth
                          id="email"
                          defaultValue={params.email ?? ""}
                          variant="outlined"
                          size="small"
                          type="email"
                          placeholder="email ini untuk verifikasi account"
                          onChange={onChangeInput}
                        />
                      </Flex>
                    </Flex>
                    <Flex flexDirection={{ sm: 'column', md: "row" }}>
                      <Flex flex={2}>
                        <Text fontSize={{ sm: 'sm', md: 'md' }}>Password</Text>
                      </Flex>
                      <Flex flex={0.5} display={{ sm: 'none', md: 'flex' }}>
                        <Text fontSize={{ sm: 'sm', md: 'md' }}>:</Text>
                      </Flex>
                      <Flex flex={10}>
                        <OutlinedInput
                          hiddenLabel
                          fullWidth
                          id="password"
                          defaultValue={params.password ?? ""}
                          variant="outlined"
                          size="small"
                          type={showPassword ? "text" : "password"}
                          placeholder="harus mempunyai huruf dan angka"
                          onChange={onChangeInput}
                          endAdornment={
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                                edge="end"
                              >
                                {showPassword ? <VisibilityOff /> : <Visibility />}
                              </IconButton>
                            </InputAdornment>
                          }
                        />
                      </Flex>
                    </Flex>
                  </>
                ) : (
                  <>
                    <Flex flexDirection={{ sm: 'column', md: "row" }}>
                      <Flex flex={2}>
                        <Text fontSize={{ sm: 'sm', md: 'md' }}>Date of Birth</Text>
                      </Flex>
                      <Flex flex={0.5} display={{ sm: 'none', md: 'flex' }}>
                        <Text fontSize={{ sm: 'sm', md: 'md' }}>:</Text>
                      </Flex>
                      <Flex flex={10}>
                        <TextField
                          hiddenLabel
                          fullWidth
                          id="birthDate"
                          defaultValue={params.dateBirth ?? ""}
                          variant="outlined"
                          type="date"
                          size="small"
                          onChange={onChangeInput}
                        />
                      </Flex>
                    </Flex>
                    <Flex flexDirection={{ sm: 'column', md: "row" }}>
                      <Flex flex={2}>
                        <Text fontSize={{ sm: 'sm', md: 'md' }}>Time of Birth</Text>
                      </Flex>
                      <Flex flex={0.5} display={{ sm: 'none', md: 'flex' }}>
                        <Text fontSize={{ sm: 'sm', md: 'md' }}>:</Text>
                      </Flex>
                      <Flex flex={10}>
                        <TextField
                          hiddenLabel
                          fullWidth
                          id="birthTime"
                          defaultValue={params.timeBirth ?? ""}
                          variant="outlined"
                          type="time"
                          size="small"
                          onChange={onChangeInput}
                        />
                      </Flex>
                    </Flex>
                    <Flex flexDirection={{ sm: 'column', md: "row" }}>
                      <Flex flex={2}>
                        <Text fontSize={{ sm: 'sm', md: 'md' }}>Gender</Text>
                      </Flex>
                      <Flex flex={0.5} display={{ sm: 'none', md: 'flex' }}>
                        <Text fontSize={{ sm: 'sm', md: 'md' }}>:</Text>
                      </Flex>
                      <Flex flex={10}>
                        <RadioGroup
                          row
                          id="gender"
                          onChange={onChangeInput}
                          value={params?.gender ? Number(params.gender) : null}
                        >
                          <FormControlLabel value={1} control={<Radio id="gender" />} label="Female" />
                          <FormControlLabel value={0} control={<Radio id="gender" />} label="Male" />
                        </RadioGroup>
                      </Flex>
                    </Flex>
                  </>
                )}
              </div>
            </div>
            <div className={styles.containerButton}>
              <Button
                borderWidth={1}
                // borderColor="#1877f2"
                bg={"green"}
                color='white'
                fontSize='sm'
                fontWeight='bold'
                borderRadius='5px'
                px='25px'
                py='5px'
                onClick={() => handleNext()}
              // disabled={pagination.page === pagination.max}
              >
                {active !== 1 ? "Next" : "Sign Up"}
              </Button>
            </div>
          </div>
        </div>
        <BootstrapDialog
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={open}
        >
          <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
            Pendaftaran berhasil
          </DialogTitle>
          {/* <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton> */}
          <DialogContent dividers>
            <Flex flexDirection="row" wrap="wrap" maxWidth="100%" gap={8} alignItems="center" justifyContent="center">
              <Text>Terima kasih telah mendaftar. Langkah terakhir yang perlu Anda lakukan adalah memverifikasi email Anda. Jika Anda tidak menemukan email verifikasi di kotak masuk, silakan periksa folder spam atau promosi.</Text>
            </Flex>
          </DialogContent>
          <DialogActions>
            <Button
              borderWidth={1}
              // borderColor="#1877f2"
              bg={"green"}
              color='white'
              fontSize='sm'
              fontWeight='bold'
              borderRadius='5px'
              px='10px'
              py='5px'
              onClick={() => window.location.assign('/login')}
            // disabled={pagination.page === pagination.max}
            >
              OK
            </Button>
          </DialogActions>
        </BootstrapDialog>
      </div>
      <Footer />
    </>
  )
}

export default Signup
