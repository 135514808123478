import React from "react";
// import Header from "../../components/Header";
// import Footer from "../../components/Footer";
import useStyles from './style';
import DashboardProfiling from "../../assets/img/DashboardProfiling.png"
import DashboardMatching from "../../assets/img/DashboardMatching.png"
import DashboardPersona from "../../assets/img/DashboardPersona.png"
import FriendApps from "../../assets/img/FriendApps.png"
import FriendCol from "../../assets/img/FriendCol.png"
import HistoryMatching from "../../assets/img/HistoryMatching.png"
import HistoryPersona from "../../assets/img/HistoryPersona.png"
import HistoryProfiling from "../../assets/img/HistoryProfiling.png"
import Header from "components/header";
import Footer from "components/footerUser";
import 'assets/css/About.css';
import { Flex, Text } from "@chakra-ui/react";

const Dashboard = () => {
  const styles = useStyles();

  return (
    <div>
      <div className={styles.app}>
        <Header active="dashboard" />
        <div className={styles.containerListItem}>
          <Flex gap={5}>
            <Flex flex={1} direction="column" borderWidth={1} borderRadius={5} px={3} py={3}>
              <Text fontWeight={700} color="#3572EF" fontSize="xl">PROFILE Dashboard</Text>
              <Text>Didalam tampilan dashboard ini Anda dapat melihat Profile Anda, beberapa aktifitas yang dapat Anda lakukan didalam Dashboard Profile ini adalah:</Text>
              <ul>
                <li>
                  <Text><strong>Edit Profile</strong> : Anda dapat melakukan perubahan/edit Profile sesuai dengan ketentuan didalam form edit profile tersebut.</Text>
                </li>
                <li>
                  <Text>Melihat <strong>Layanan Profiling</strong> serta aktifitas <strong>melihat harga</strong> hingga melakukan <strong>pembelian</strong> terhadap Service Profiling yang ada di dalam list service Type Profiling.</Text>
                </li>
                <li>
                  <Text>Melihat <strong>Layanan Matching</strong> serta aktifitas <strong>melihat harga</strong> hingga melakukan <strong>pembelian</strong> terhadap Service Matching yang ada di dalam list service Type Matching.</Text>
                </li>
                <li>
                  <Text>Melihat <strong>Layanan Web View Service</strong> serta aktifitas <strong>melihat harga</strong> hingga melakukan <strong>pembelian</strong> terhadap Service Web View Service yang ada di dalam list service Type Web View Service.</Text>
                </li>
              </ul>
            </Flex>
            <Flex direction="column" flex={1}>
              <img src={DashboardProfiling} />
              <img src={DashboardMatching} />
              <img src={DashboardPersona} />
            </Flex>
          </Flex>
          <Flex gap={5}>
            <Flex flex={1} direction="column" borderWidth={1} borderRadius={5} px={3} py={3}>
              <Text fontWeight={700} color="#3572EF" fontSize="xl">FRIENDS LIST Dashboard </Text>
              <Text>Didalam tampilan dashboard ini Anda dapat melihat Friends List Anda, beberapa aktifitas yang dapat Anda lakukan didalam Dashboard ini adalah:</Text>
              <ul>
                <li>
                  <Text><strong>Add Friend Appse</strong> : menambahkan teman Anda dari list user yang sudah terdaftar didalam Persona Talenta, dengan fasilitas <strong>Search User</strong>.</Text>
                </li>
                <li>
                  <Text><strong>Add Colleague</strong> menambahkan teman Anda dengan cara <strong>memasukkan manual didalam form Colleague</strong>. Ini memberikan Anda keuntungan untuk menambah teman sesuai dengan keiningan Anda.</Text>
                </li>
                <li>
                  <Text>Dari penambahan teman Anda melalui Add Friends Apps dan Add Colleague <strong>akan dimasukkan teman Anda tersebut kedalam list</strong> Friends Colleague dan Friends From Apps</Text>
                </li>
                <li>
                  <Text>Pada Friends Request List adalah list dari user lain yang ingin berteman dengan Anda, ketika <strong>Privacy</strong> Anda disetting <strong>Private</strong>, sehingga setiap permintaann berteman akan di konfirmasi ke Anda melalui Dashboard ini.</Text>
                </li>
              </ul>
            </Flex>
            <Flex direction="column" flex={1}>
              <img src={FriendApps} />
              <img src={FriendCol} />
            </Flex>
          </Flex>
          <Flex gap={5}>
            <Flex flex={1} direction="column" borderWidth={1} borderRadius={5} px={3} py={3}>
              <Text fontWeight={700} color="#3572EF" fontSize="xl">PURCHASE HISTORY Dashboard </Text>
              <Text>Didalam tampilan dashboard ini Anda dapat melihat Riwayat Pembelian Layanan yang telah Anda lakukan, beberapa aktifitas yang dapat Anda lakukan didalam Dashboard ini adalah:</Text>
              <ul>
                <li>
                  <Text><strong>Profiling Service</strong> : Dashboard ini menampilkan list profiling service yang sudah Anda Beli. Sehingga Anda dapat melakukan Download file pdf terhadap layanan profiling yang sudah dibeli tersbut dengan klik icon Download.</Text>
                </li>
                <li>
                  <Text><strong>Matching Service</strong> : didalam dashboard ini adalah list dari mathing yang sudah Anda beli. Anda dapat melihat hasil perubahan Daily Matching dengan klik View Result sampai dengan layanan Anda Expired.</Text>
                </li>
                <li>
                  <Text><strong>Web View Service</strong> : didalam dashboard ini menampilkan list layanan yang bisa Anda lihat hanya didalam website, yanitu dengan klik icon web view yang tersedia.</Text>
                </li>
              </ul>
            </Flex>
            <Flex direction="column" flex={1}>
            <img src={HistoryProfiling} />
            <img src={HistoryMatching} />
            <img src={HistoryPersona} />
            </Flex>
          </Flex>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default Dashboard