import React, { useEffect, useState } from "react";
import { Button, Flex, Text } from "@chakra-ui/react";
import Header from "components/header";
import ProfilingComplete from '../../assets/img/ProfilingComplete.webp';
import ProfilingStandard from '../../assets/img/ProfilingStandard.webp';
import ProfilingPilihanCareerTerbaik from '../../assets/img/ProfilingPilihanCareerTerbaik.webp';
import ProfilingSifatdanSikapDitempatKerja from '../../assets/img/ProfilingSifatdanSikapDitempatKerja.webp';
import ProfilingHubunganCintadanSosial from '../../assets/img/ProfilingHubunganCintadanSosial.webp';
import useStyles from './style';
import Footer from "components/footerUser";

const Profiling = props => {
  const styles = useStyles();
  const [activePage, setActivePage] = useState(0);

  useEffect(() => {
    if (props?.location?.hash?.split('#')[1]) {
      setActivePage(props?.location?.hash?.split('#')[1])
    }
  }, [])

  return (
    <Flex direction="column" gap={5}>
      <Header active="profiling" />
      <Flex justifyContent="center" mt={16} width="100%">
        <Flex maxWidth={1080} overflowX="hidden" width="100%" direction="column">
          <Flex gap={{sm: 3, md: 5}} flexWrap="nowrap" overflowX="auto" style={{scrollbarWidth: 'none'}} width="100%">
            <Flex direction="column" flex={1}>
              <img src={ProfilingPilihanCareerTerbaik} alt="profile" className={styles.img} />
              <Flex width={{sm: 150, md: '100%'}} backgroundColor={activePage == 0 ? "#B02418" : "#016A70"} cursor="pointer" borderTopRadius={10} mb={2} p={2} h={70} justifyContent="center" alignItems="center" onClick={() => setActivePage(0)}>
                <Text as="h1" color="#fff" fontSize={{sm: 'xs', md: 'sm'}} fontWeight="bold" textAlign="center">PILIHAN KARIR TERBAIK</Text>
              </Flex>
            </Flex>
            <Flex direction="column" flex={1}>
              <img src={ProfilingSifatdanSikapDitempatKerja} alt="profile" className={styles.img} />
              <Flex width={{sm: 150, md: '100%'}} backgroundColor={activePage == 1 ? "#B02418" : "#016A70"} cursor="pointer" borderTopRadius={10} mb={2} p={2} h={70} justifyContent="center" alignItems="center" onClick={() => setActivePage(1)}>
                <Text as="h1" color="#fff" fontSize={{sm: 'xs', md: 'sm'}} fontWeight="bold" textAlign="center">SIFAT DAN SIKAP DITEMPAT KERJA</Text>
              </Flex>
            </Flex>
            <Flex direction="column" flex={1}>
              <img src={ProfilingHubunganCintadanSosial} alt="profile" className={styles.img} />
              <Flex width={{sm: 150, md: '100%'}} backgroundColor={activePage == 2 ? "#B02418" : "#016A70"} cursor="pointer" borderTopRadius={10} mb={2} p={2} h={70} justifyContent="center" alignItems="center" onClick={() => setActivePage(2)}>
                <Text as="h1" color="#fff" fontSize={{sm: 'xs', md: 'sm'}} fontWeight="bold" textAlign="center">HUBUNGAN CINTA DAN SOSIAL</Text>
              </Flex>
            </Flex>
            <Flex direction="column" flex={1}>
              <img src={ProfilingStandard} alt="profile" className={styles.img} />
              <Flex width={{sm: 150, md: '100%'}} backgroundColor={activePage == 3 ? "#B02418" : "#016A70"} cursor="pointer" borderTopRadius={10} mb={2} p={2} h={70} justifyContent="center" alignItems="center" onClick={() => setActivePage(3)}>
                <Text as="h1" color="#fff" fontSize={{sm: 'xs', md: 'sm'}} fontWeight="bold" textAlign="center">PROFILLING ANALITIK STANDARD</Text>
              </Flex>
            </Flex>
            <Flex direction="column" flex={1}>
              <img src={ProfilingComplete} alt="profile" className={styles.img} />
              <Flex width={{sm: 150, md: '100%'}} backgroundColor={activePage == 4 ? "#B02418" : "#016A70"} cursor="pointer" borderTopRadius={10} mb={2} p={2} h={70} justifyContent="center" alignItems="center" onClick={() => setActivePage(4)}>
                <Text as="h1" color="#fff" fontSize={{sm: 'xs', md: 'sm'}} fontWeight="bold" textAlign="center">PROFILLING ANALITIK COMPLETE</Text>
              </Flex>
            </Flex>
          </Flex>
          <Flex direction="column" padding={5} borderWidth={1} borderColor="#B02418" borderRadius={20}>
            {activePage == 0 ? (
              <Flex direction="column" gap={3}>
                <Text fontSize={{sm: 'sm', md: 'md'}}>Analisa karier ini menggunakan data input berupa tanggal dan jam lahir, kemudian diolah dengan algoritma Persona tertentu untuk menghasilkan berbagai kode sebagai variable input analisis. Variabel-variabel ini akan menentukan seberapa besar akurasi yang akan didapat dari sebuah metode prediktif analitik ini. Sebagian besar variabel analisis diperoleh dari perhitungan dengan menggunakan analisa delapan aksara, ditambahkan dengan beberapa variabel lain yang berpengaruh.</Text>
                <Text fontSize={{sm: 'sm', md: 'md'}}>Dengan menggabungkan berbagai elemen ini, Personatalenta mampu memberikan gambaran komprehensif tentang potensi karier seseorang. Metode ini juga memungkinkan untuk pemahaman yang lebih mendalam tentang karakter dan kecenderungan seseorang, serta bagaimana faktor-faktor tersebut dapat diterapkan dalam konteks professional. Dengan pendekatan yang holistik, analisis karier ini merupakan prediksi analitis awal untuk memberikan gambaran dan membantu dalam merencanakan dan mengembangkan jalur karier dengan lebih baik.</Text>
              </Flex>
            ) : activePage == 1 ? (
              <Flex direction="column" gap={3}>
                <Text fontSize={{sm: 'sm', md: 'md'}}>Dengan menggunakan informasi profiling ini, kami bantu identifikasi sifat dan sikap kerja seseorang, sehingga dapat digunakan untuk memprediksi dan meningkatkan dinamika kerja perusahaan. Analisis ini sangat berguna dalam membantu Anda memahami bagaimana mereka bereaksi terhadap situasi tertentu dan bagaimana mereka berinteraksi dengan rekan kerja, yang pada gilirannya dapat meningkatkan efektivitas dan harmoni di tempat kerja.</Text>
                <Text fontSize={{sm: 'sm', md: 'md'}}>Dalam konteks profesional, metode delapan aksara yang kami gunakan, dapat menjadi alat yang sangat berharga untuk manajemen sumber daya manusia. Dengan memahami sifat dasar karyawan, pemimpin dan manajer dapat lebih efektif dalam mengatur tim, mencocokkan tugas dengan kemampuan alami karyawan, dan merancang pengembangan yang sesuai. Ini juga membantu dalam mengidentifikasi potensi konflik dan harmoni antar karyawan, memungkinkan manajemen untuk proaktif dalam menciptakan lingkungan kerja yang lebih kooperatif dan produktif.</Text>
                <Text fontSize={{sm: 'sm', md: 'md'}}>Akhirnya, dengan memanfaatkan profiling ini, perusahaan dapat memaksimalkan kepuasan kerja dan potensi karyawan, sehingga mencapai hasil yang optimal dan mempertahankan keseimbangan positif di tempat kerja.</Text>
              </Flex>
            ) : activePage == 2 ? (
              <Flex direction="column" gap={3}>
                <Text fontSize={{sm: 'sm', md: 'md'}}>Metode profiling Persona Talenta ini memberikan wawasan tentang dinamika hubungan cinta dan sosial melalui analisis tanggal dan waktu lahir Anda. Teknik ini mengeksplorasi karakteristik pribadi dan kecenderungan perilaku yang dimiliki seseorang, memungkinkan pemahaman yang lebih baik tentang bagaimana Anda berinteraksi dalam hubungan romantis maupun sosial, membantu mengidentifikasi harmoni Anda dan pasangan atau partner yang sangat berguna dalam mengelola dan memperkuat hubungan.</Text>
                <Text fontSize={{sm: 'sm', md: 'md'}}>Dalam profiling hubungan cinta, memungkinkan Anda dan pasangan untuk lebih memahami satu sama lain pada level yang lebih dalam, memfasilitasi komunikasi yang lebih efektif dan mengurangi kesalahpahaman. Hal ini tidak hanya meningkatkan keintiman dalam hubungan, tetapi juga memberi peluang bagi kedua belah pihak untuk bersama dalam menghadapi tantangan yang mungkin muncul.</Text>
                <Text fontSize={{sm: 'sm', md: 'md'}}>Dalam konteks sosial, profiling ini dapat membantu Anda memahami bagaimana Anda dapat berinteraksi lebih baik dengan berbagai tipe kepribadian, memperluas jaringan sosial dan meningkatkan keterampilan interpersonal. Oleh karena itu, metoda kami ini bukan hanya alat untuk analisis, tetapi juga panduan untuk menciptakan hubungan yang lebih memuaskan dan kooperatif, baik dalam cinta maupun dalam interaksi sosial sehari-hari.</Text>
              </Flex>
            ) : activePage == 3 ? (
              <Flex direction="column" gap={3}>
                <Text fontSize={{sm: 'sm', md: 'md'}}>Profiling dengan menggunakan metoda Persona mengandung banyak informasi tentang seseorang berdasarkan data kelahirannya: tanggal,bulan, dan tahun kelahiran. Profiling yang kami lakukan secara komputasi otomatis ini dengan output hasil sebagai berikut:</Text>
                <ol>
                  <li><Text fontSize={{sm: 'sm', md: 'md'}}>Personalitas atau Kepribadian: wawasan tentang karakteristik kepribadian seseorang, termasuk gaya berkomunikasi, sifat bawaan, preferensi, dan kecenderungan emosional.</Text></li>
                  <li><Text fontSize={{sm: 'sm', md: 'md'}}>Potensi Diri: mengidentifikasi potensi dan bakat alami seseorang. Misalnya, seseorang mungkin memiliki kecenderungan terhadap kreativitas, kepemimpinan, atau keterampilan analitis tertentu.</Text></li>
                  <li><Text fontSize={{sm: 'sm', md: 'md'}}>Kekuatan dan Kelemahan : mengidentifikasi kekuatan dan kelemahan yang ada dalam probadi seseorang baik yang terlihat maupun yang tersembunyi. Mengenali sisi positif dan negatif dari personalitas individu.</Text></li>
                  <li><Text fontSize={{sm: 'sm', md: 'md'}}>Karakter Kerja dan Gaya Kerja : memberikan gambaran tentang karkater kerja dan juga gaya bekerja yang akan dilakukan saat di tempat kerja </Text></li>
                  <li><Text fontSize={{sm: 'sm', md: 'md'}}>Karier yang cocok: membantu dalam pemahaman tentang jalur karir yang cocok untuk seseorang dan bagaimana meningkatkan peluang kesuksesan dalam karier.</Text></li>
                  <li><Text fontSize={{sm: 'sm', md: 'md'}}>Kesejahteraan Keuangan: memberikan gambaran tentang keberuntungan finansial seseorang, tetapi juga memberikan wawasan tentang kecenderungan dalam mengelola uang dan investasi.</Text></li>
                  <li><Text fontSize={{sm: 'sm', md: 'md'}}>Path To Growth: mengidentifikasi tantangan yang mungkin dihadapi seseorang dalam hidup, serta peluang untuk pertumbuhan dan perkembangannya.</Text></li>
                </ol>
              </Flex>
            ) : activePage == 4 ? (
              <Flex direction="column" gap={3}>
                <Text fontSize={{sm: 'sm', md: 'md'}}>Profiling Analitik Complete ini memiliki hasil seperti pada Profiling Analitik Standard yang meliputi:</Text>
                <ol>
                  <li><Text fontSize={{sm: 'sm', md: 'md'}}><strong>Personalitas atau Kepribadian</strong></Text></li>
                  <li><Text fontSize={{sm: 'sm', md: 'md'}}><strong>Potensi Diri</strong></Text></li>
                  <li><Text fontSize={{sm: 'sm', md: 'md'}}><strong>Kekuatan dan Kelemahan</strong></Text></li>
                  <li><Text fontSize={{sm: 'sm', md: 'md'}}><strong>Karakter Kerja dan Gaya Kerja</strong></Text></li>
                  <li><Text fontSize={{sm: 'sm', md: 'md'}}><strong>Karier yang cocok</strong></Text></li>
                  <li><Text fontSize={{sm: 'sm', md: 'md'}}><strong>Kesejahteraan Keuangan</strong></Text></li>
                  <li><Text fontSize={{sm: 'sm', md: 'md'}}><strong>Path To Growth</strong></Text></li>
                  <Text py={3}>ditambah dengan tambahan hasil analitis lainnya yaitu:</Text>
                  <li><Text fontSize={{sm: 'sm', md: 'md'}}><strong>Profile dalam menjalin Hubungan</strong> : mengidentifikasi sifat sifat dalam hubungan sosial, maupun hubugan yang bersifat intim misalnya terkait dengan hubungan romance, pertemanan dan partner kerja termasuk cara-cara dalam menjalin hubungan.</Text></li>
                  <li><Text fontSize={{sm: 'sm', md: 'md'}}><strong>Relasi Hubungan Cinta, Kehidupan Seksual dan Keluarga</strong>: mengindikasikan bagaimana cara menjalin hubungan cinta dan relasi yang bersifat intimate dalan hubungan dengan pasangan cinta maupun dengan keluarga.</Text></li>
                  <li><Text fontSize={{sm: 'sm', md: 'md'}}><strong>Kesehatan</strong> : Gambaran Kesehatan dan preferensi cara untuk dapat menjaga Kesehatan</Text></li>
                  <li><Text fontSize={{sm: 'sm', md: 'md'}}><strong>Kesejahteran dan Jalan Sejahtera</strong>: mengidentifikasi berbagai kemungkinan dari perjalanan untuk mencapai Sejahtera sebagai saran bagi individu yang sedang dalam mengembangkan kesejahterannya.</Text></li>
                  <li><Text fontSize={{sm: 'sm', md: 'md'}}><strong>Predictive analitik tahun berjalan</strong> : memeberikan Gambaran terhadap beberapa factor dalam tahun berjalan ini dan menempuh perjalanan nya.</Text></li>
                </ol>
              </Flex>
            ) : null}
            <Flex justify="end" align="end">
              <Button
                // width="100%"
                bgColor="#B02418"
                color='white'
                fontSize={{ sm: 'xs', md: 'sm' }}
                fontWeight='500'
                borderRadius='5px'
                onClick={() => window.location.assign('/pricing#profiling')}
                px='15px'
                py='5px'>
                Lihat pricing
              </Button>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
      <Footer />
    </Flex>
  )
}

export default Profiling;
