import { Flex, Text } from '@chakra-ui/react';
import axios from 'axios';
import Header from 'components/header';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import PricingMatching from 'views/pricingMatching';
import PricingPersona from 'views/pricingPersona';
import PricingProfiling from 'views/pricingProfiling';

const Pricing = (props) => {
  const [user, setUser] = useState();
  const [data, setData] = useState()
  const [service, setService] = useState()
  const location = useLocation();

  useEffect(() => {
    let localUser = localStorage.getItem('user');
    if (localUser) {
      setUser(JSON.parse(localUser))
    }
  }, [])

  useEffect(() => {
    if (user || data?.id) {
      const token = localStorage.getItem('token');
      let baseUrl = '';
      if (data?.id) {
        baseUrl = `https://api.personatalenta.id/user/service/table-list-friend/${data?.id}?is_has_user_id=${data?.type === 'friend'}`
      } else {
        baseUrl = 'https://api.personatalenta.id/user/service/table-list'
      }
      axios.get(baseUrl, {headers: {Authorization: `Bearer ${token ? token : ''}`, "Content-Type": "x-www-from-urlencode"}})
      .then(res => {
        if (res?.data?.products) {
          setService(res.data.products)
        }
      }).catch(err => {
        if (err.response.data.message === "Unauthorized" || err.response.data === "Unauthorized") {
          // navigate('/');
        }
      })
    }
  }, [user, data])

  useEffect(() => {
    let ignore = false;

    const scrollToElement = () => {
      if (location.hash && !ignore) {
        const item = location.hash?.slice(1)?.split('?')
        const pairs = item[1]?.split('&');
        // Initialize an empty object to store the result
        const result = {};
        // Iterate over each pair
        pairs?.forEach(pair => {
          // Split each pair by '=' to get the key and value
          const [key, value] = pair.split('=');
          result[key] = value;
        });
        setData(result)
        const element = document.getElementById(item[0]); // Remove '#' from hash
        if (element) {
          element.scrollIntoView({ behavior: 'smooth' });
        } else {
          console.log("Element not found:", location.hash);
        }
      }
    };

    // Delay the scroll action to give time for rendering the target element
    const timeoutId = setTimeout(scrollToElement, 500); // 500ms delay

    // Cleanup function
    return () => {
      clearTimeout(timeoutId);
      ignore = true;
    };
  }, [location.hash]);

  return (
    <Flex direction="column">
      <Header />
      <Flex alignItems="center" direction="column" mt={20} gap={10} pl="20px">
        <Flex id="profiling" direction="column" alignItems="start" maxWidth={1080} width="100%">
          <Text fontWeight="bold" fontSize="lg">PRICING PROFILING</Text>
          <Flex direction="row" mt={5} overflowX="hidden" width="100%">
            <PricingProfiling product={service?.profiling} user={user} data={data} />
          </Flex>
        </Flex>
        {!data?.type && (
          <Flex id="matching" direction="column" alignItems="start" maxWidth={1080} width="100%">
            <Text fontWeight="bold" fontSize="lg">PRICING MATCHING</Text>
            <Flex direction="row" mt={5} overflowX="hidden" width="100%">
              <PricingMatching product={service?.matching} user={user} data={data} />
            </Flex>
          </Flex>
        )}
        <Flex id="persona" direction="column" alignItems="start" maxWidth={1080} width="100%">
          <Text fontWeight="bold" fontSize="lg">PRICING PERSONA</Text>
          <Flex direction="row" mt={5} overflowX="hidden" width="100%">
            <PricingPersona product={service?.persona} user={user} data={data} />
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  )
}

export default Pricing;
